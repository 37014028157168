/* eslint-disable max-depth */
import React, { useEffect } from "react";
import queryString from "query-string";
import Cookies from "js-cookie";
import axios from "axios";
import { navigate } from "gatsby";
import { ToastContainer } from "react-toastify";
import jwtDecode from "jwt-decode";
import { baseURL } from "../utils/axios";
import { PathTypes } from "../components/PageComponents/Dashboard/Auctions/NewAuctionCreation/Types/PathTypes";
import { Paths } from "../enums/Paths";
import { AuctionType } from "../components/PageComponents/Dashboard/Auctions/NewAuctionCreation/NewCreateAuction";
import FullScreenLoading from "../components/Loading/FullScreenLoading";

const AuthRedirect = () => {
  useEffect(() => {
    (async () => {
      if (typeof window !== "undefined") {
        const info = queryString.parse(window.location.search);
        if (info.redirectURL) {
          navigate(info.redirectURL as string, {
            state: {
              navigationPath: info.navigationPath,
              location: info.location,
            },
          });
          return;
        }
        if (info.status && info.status === "failed") {
          if (info.type === "CONNECT_INSTAGRAM") {
            if (
              info.toPath &&
              info.toPath === PathTypes.AUCTION_CREATION_MAIN
            ) {
              const path =
                info.originationPath === PathTypes.DASHBOARD
                  ? Paths.Dashboard
                  : Paths.AuctionsIndex;
              navigate(path);
              return;
            }
            if (info.toPath && info.fromPath === "CHOOSE_ACCOUNT") {
              navigate(
                (info?.toPath as string) || Paths.AuctionCreateChooseAccount
              );
              return;
            }
            navigate(Paths.AuctionCreate, {
              state: {
                error:
                  "There was an error with Facebook authentication. Please Try again.",
                ...info,
              },
            });
            return;
          }
          if (info.type === "READY_TO_ROLL") {
            navigate(Paths.AuctionCreate, {
              state: {
                error:
                  "There was an error with Facebook authentication. Please Try again.",
                ...info,
              },
            });
            return;
          }
          Cookies.set("auth_failure", "true");

          navigate(Paths.Dashboard);
          return;
        }
        if (info.type === "CONNECT_INSTAGRAM") {
          if (info.toPath) {
            if (info.fromPath && info.fromPath === "CHOOSE_ACCOUNT") {
              navigate(
                (info?.toPath as string) || Paths.AuctionCreateChooseAccount
              );
              return;
            }
            switch (info.toPath) {
              case PathTypes.AUCTION_CREATION_MAIN:
                navigate(Paths.AuctionCreate, { state: { ...info } });
                return;
              default:
                break;
            }
          }
          if (info.selectedAuctionType) {
            switch (info.selectedAuctionType) {
              case AuctionType.QUICKSTART:
                navigate(Paths.AuctionsQuickstart, {
                  state: { ...info },
                });
                return;
              case AuctionType.QUICKSTARTPLUS:
                // TODO: add this
                return;
              default:
                // TODO: add this
                return;
            }
          }
        }
        if (info.token) {
          const refreshToken = info.token;

          try {
            const response = await axios.post(`${baseURL}/refresh/`, {
              refresh_token: refreshToken,
            });
            const newAccess = response.data.token;
            const newRefresh = response.data.refresh_token;
            const accountType = response.data.type;

            const decodedAccess: { [key: string]: any } = jwtDecode(newAccess);
            const decodedRefresh: { [key: string]: any } =
              jwtDecode(newRefresh);
            Cookies.set("access", newAccess, {
              expires: new Date(decodedAccess.exp * 1000),
            });
            Cookies.set("refresh", newRefresh, {
              expires: new Date(decodedRefresh.exp * 1000),
            });
            if (accountType) {
              Cookies.set("accountType", accountType, {
                expires: new Date(decodedAccess.exp * 1000),
              });
            }
            if (info.type && info.type === "SIGNING_UP") {
              navigate(Paths.Dashboard);
              return;
            }

            navigate(redirectOrDashboard());
            return;
          } catch (err) {
            navigate(Paths.Dashboard);
          }
        } else {
          navigate(Paths.Dashboard);
        }
      } else {
        navigate(Paths.Dashboard);
      }
    })();
  }, []);

  /**
   * Checks for a redirect url query param.
   * Default returns dashboard path.
   *
   * Used to redirect a user back to the page they
   * originally visited before having to log in.
   *
   * @returns path (string)
   */
  const redirectOrDashboard = (): string => {
    const query = queryString.parse(window.location.search);
    if (query.redirect && (query.redirect as string).startsWith("/")) {
      return query.redirect as string;
    }
    return Paths.Dashboard;
  };

  return (
    <>
      <FullScreenLoading />
      <ToastContainer limit={1} />
    </>
  );
};

export default AuthRedirect;
